/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import S3Upload from '../S3Upload'
import { Button, Col, Drawer, Form, Input, Row, Select, Switch } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

const { Option } = Select
interface Props {
  onClose: () => void
  visible: boolean
  cdnUrl: string
  preSignUrl: string
  formSubmithandler: (values: any) => void
  form: any
  initialValues: any
  isEditJourney: boolean
}
const ItemCreation: React.FC<Props> = ({
  onClose,
  visible,
  cdnUrl,
  preSignUrl,
  formSubmithandler,
  form,
  initialValues,
  isEditJourney
}) => {
  return (
    <Drawer
      title='Create New Product'
      placement='right'
      onClose={onClose}
      visible={visible}
      destroyOnClose={true}

    >
      <Form
        form={form}
        layout='vertical'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={initialValues}
      >
        <Form.Item
          label='Name of the Product'
          name='title'
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          rules={[{ required: true, message: 'Please input item name!' }]}
        >
          <Input placeholder='Enter Name of the item' />
        </Form.Item>
        <Form.Item
          label='Description'
          name='description'
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 20 }}
          rules={[{ required: true, message: 'Please input descrpition!' }]}
        >
          <TextArea placeholder='Enter description of the item' />
        </Form.Item>

        <Form.Item
          label={`Product price`}
          name='price'
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          rules={[{ required: true, message: 'Please input price of item!' }]}
        >
          <Input type={'number'} placeholder={`price`} />
        </Form.Item>
        <Form.Item
          label={`Product type`}
          name='item_type'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          rules={[{ required: true, message: 'Please select item type!' }]}
        >
          <Select style={{ width: 100 }} placeholder='Type'>
            <Option value='item'>Item</Option>
            <Option value='prayer'>Prayer</Option>
            <Option value='pooja'>Pooja</Option>
            <Option value='sankalp'>Sankalp</Option>
          </Select>
        </Form.Item>
        <Row>
          <Col span={12}>

            <Form.Item
              label='Image URL'
              name='image_url'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              valuePropName='fileList'
              rules={[
                {
                  min: 1,
                  max: 1,
                  validator: async (rules: any, values: any) => {
                    if (values?.length < rules.min) {
                      return Promise.reject('Please upload Image')
                    }
                  },
                },
              ]}
            >
              <S3Upload cdnUrl={cdnUrl} preSignUrl={preSignUrl} min={1} max={1} />
            </Form.Item>
            <Form.Item
              label='Bottom sheet image'
              name='bottomsheet_image_url'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              valuePropName='fileList'
              rules={[
                {
                  min: 1,
                  max: 1,
                  validator: async (rules: any, values: any) => {
                    if (values?.length < rules.min) {
                      return Promise.reject('Please upload bottom sheet Image')
                    }
                  },
                },
              ]}
            >
              <S3Upload cdnUrl={cdnUrl} preSignUrl={preSignUrl} min={1} max={1} />
            </Form.Item>
          </Col>

        </Row>
        {/* IGST Applicable Toggle */}
        <Form.Item
          label='Is IGST Applicable?'
          name='is_igst'
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>


        {/* HSN Code */}
        <Form.Item
          label='HSN Code'
          name='hsn_code'
          // rules={[
          //   { pattern: /^[0-9]{6,8}$/, message: 'HSN Code must be 6 to 8 digits!' },
          // ]}
        >
          <Input placeholder='Enter HSN Code' maxLength={8} />
        </Form.Item>

        {/* Tax Rate Percentage */}
        <Form.Item
          label="Tax Rate"
          name="tax_rate"
          rules={[
            {
              validator: (_, value) => {
                if (value === undefined || value === null || value === "") {
                  return Promise.resolve(); // Required validation will handle empty values
                }

                const numValue = Number(value);
                if (isNaN(numValue) || numValue < 0 || numValue > 100) {
                  return Promise.reject("Tax Rate % must be a valid number between 0 and 100!");
                }

                return Promise.resolve();
              }
            }
          ]}
        >
          <Input type="number" placeholder="Enter Tax Rate %" />
        </Form.Item>




        <Form.Item
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ paddingTop: '30px' }}
        >
          {isEditJourney ? <Button type='primary' onClick={formSubmithandler}>
            Update Product
          </Button> : <Button type='primary' onClick={formSubmithandler}>
            Create Product
          </Button>}
        </Form.Item>
      </Form>
    </Drawer>
  )
}
export default ItemCreation
