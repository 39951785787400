import { CreateItem } from '@a4b/api/src/modules/Monetisation/Puja/types'

export const formatItemCreation = (formData: any): CreateItem => {
  const formatedObject: CreateItem = {
    title: formData.title,
    description: formData.description,
    price: Number(formData.price),
    item_type: formData.item_type,
    image_url: formData.image_url?.[0],
    bottomsheet_image_url: formData.bottomsheet_image_url?.[0],
    is_igst: formData.is_igst,
    hsn_code: formData.hsn_code || null,
    tax_rate:
      formData?.tax_rate === '' || formData?.tax_rate == null
        ? null
        : Number(formData?.tax_rate),
  }
  return formatedObject
}
