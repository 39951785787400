import React, { useEffect, useState } from 'react'
import { useForm } from 'antd/lib/form/Form'
import { message } from 'antd'
import { useAppContext } from '@/components/AppContext'
import ItemCreation from '@a4b/ui/src/modules/monetisation/puja/molecules/ItemCreation'
import { Product } from '@a4b/api/src/modules/Monetisation/Puja/types'
import { YODA_CDN_URL, PUJA_PRESIGN_URL, A4B_APPS } from '@/utils/constants'
import { formatItemCreation } from './helper'
interface Props {
  showItemCreation: boolean
  isEditJourney: boolean
  createItemCallback?: (status: boolean) => void
  UpdateItemCallback?: (status: boolean) => void
  closeItemCreation: () => void
  formData?: Product
}

const CreateItem: React.FC<Props> = ({
  showItemCreation,
  closeItemCreation,
  createItemCallback,
  UpdateItemCallback,
  isEditJourney,
  formData,
}) => {
  const { networkInstance, app } = useAppContext()
  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi

  const [editFormData, setEditFormData] = useState<any>()
  const [createItemForm] = useForm()

  useEffect(() => {
    if (isEditJourney) {
      const editForm = {
        ...formData,
        name: formData?.title,
        image_url: [formData?.image_url],
        bottomsheet_image_url: [formData?.bottomsheet_image_url],
        intro_video_url: [formData?.intro_video_url],
        outro_video_url: [formData?.outro_video_url],
        is_igst: formData?.is_igst ?? false,
      }
      setEditFormData(editForm)
      createItemForm.setFieldsValue(editForm)
    } else {
      setTimeout(() => {
        createItemForm.resetFields()
      }, 300)
      setEditFormData({})

    }
  }, [isEditJourney, formData, createItemForm])

  const handleCreateOrUpdate = async (values: any) => {
    if (isEditJourney) {
      try {
        const updateFormData = formatItemCreation(values)
        const id = formData?.id || ''
        const updateItem = await monetisationPujaApi.productApi.updateItem(
          { ...updateFormData, ...{ id: id } },
          id,
        )
        if (updateItem) {
          message.success('Item updated successfully')

          if (UpdateItemCallback) {
            UpdateItemCallback(true)
          }
        } else {
          message.error('Item update failed')
        }
        createItemForm.resetFields()
        closeItemCreation()
      } catch (error: any) {
        message.error({
          content: error?.response?.data?.error?.args?.type?.[0] || 'Error While updating item',
          duration: 2,
          key: 'update-item',
        })
      }
    } else {
      try {
        const createFormData = formatItemCreation(values)
        message.loading({ content: 'Creating item...', key: 'create-item' })
        await monetisationPujaApi.productApi.createItem(
          createFormData,
        )
        message.success({
          content: 'Item Created Successfully',
          duration: 2,
          key: 'create-item',
        })
        createItemForm.resetFields()
        closeItemCreation()
      } catch (error: any) {
        message.error({
          content: error?.response?.data?.error?.args?.type?.[0] || 'Error While creating item',
          duration: 2,
          key: 'create-item',
        })
      }
    }
  }

  const formSubmithandler = () => {
    createItemForm
      .validateFields()
      .then(values => {
        handleCreateOrUpdate(values)
      })
      .catch(errorInfo => { })
  }
  return (
    <ItemCreation
      visible={showItemCreation}
      onClose={closeItemCreation}
      cdnUrl={YODA_CDN_URL}
      preSignUrl={PUJA_PRESIGN_URL}
      formSubmithandler={formSubmithandler}
      form={createItemForm}
      initialValues={editFormData}
      isEditJourney={isEditJourney}
    />
  )
}

export default CreateItem
