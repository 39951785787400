import { useAppContext } from '@/components/AppContext'
import { YODA_CDN_URL, PUJA_PRESIGN_URL, A4B_APPS } from '@/utils/constants'
import { Modal, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import InventoryList from '../components/InventoryList'
import InventoryForm from '../components/InventoryForm'

import { LanguageState, Product } from '@a4b/api/src/modules/Monetisation/Puja/types'

import {
  formatInventoryForEdit,
  formatInventoryForEditAdd,
  parseStoreProductList,
} from '../utils/helper'
import { TablePaginationConfig } from 'antd/es/table'
import Error from '@/components/Error'
import ConfirmationPopup from '../components/ConfirmationPopup'
import usePujaStore from '../../../stores/puja-store'

interface Props {
  isEditJourney: boolean
  currentStep: number
  storeId: string
  nextHandler: (store_id?: string) => void
  previousHandler: () => void
  languageState: LanguageState
}
type AddedItems = { added: Product[] }
const AssigningOfferStep: React.FC<Props> = ({
  currentStep,
  isEditJourney,
  storeId,
  previousHandler,
  nextHandler,
  languageState
}) => {
  const { networkInstance, countryState, app } = useAppContext()
  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
  const [productList, setProductList] = useState<Product[]>([])
  const [addedList, setAddedList] = useState<AddedItems>({
    added: [],
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const [showItemCreation, setShowItemCreation] = useState(false)
  const [campaignList, setCampaignList] = useState<any>()
  const [filters, setFilters] = useState({ item_type: 'item' })

  const [showInventoryCreation, setShowInventoryCreation] = useState<boolean>(false)
  const [isEditJourneyForm, setIsEditJourneyForm] = useState<boolean>(false)
  const [editInventory, setEditInventory] = useState<any>()
  const [createInventoryForm] = useForm()
  const [paginationParams, setPaginationParams] =
    useState<TablePaginationConfig>({
      current: 1,
      pageSize: 50,
    })

  const [nameListId, setNameListId] = useState<string>('')
  const [inventoryData, setInventoryData] = useState<any>()
  const [visible, setVisible] = useState<boolean>(false)
  const [errorPage, setErrorPage] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [refetch, setRefetch] = useState<any>()
  const [resetTrigger, setResetTrigger] = useState<boolean>(false);

  const { experiment, variant } = usePujaStore()

  const getCampaignList = async () => {
    try {
      const CampaignListResponse = await networkInstance.clientWithHeaders.monetisationPujaApi.campaignsAPi.getCampaignList({
        limit: 1000,
        offset: 0,
      }, true)
      return CampaignListResponse
    } catch (error) {
      message.error('Error while fetching campaign list')
      return []
    }
  }

  const updateSortBy = (sortBy?: ('price' | 'position' | 'created_at')) => {
    if (sortBy) {
      searchParams.set('sort_by', sortBy)
    } else {
      searchParams.delete('sort_by')
    }
    setSearchParams(searchParams)
  }

  const getStoreList = async () => {
    try {
      const storeList = await monetisationPujaApi.storesApi.getStoreList({
        limit: 1000,
        offset: 0,
        service_type: 'offerings'
      }, countryState?.countryCode)

      return storeList?.data?.data?.store_list.sort((item1: any, item2: any) => item1.position - item2.position)
    } catch (error) {
      message.error({
        content: 'Error while fetching store list',
        duration: 3,
      })
      return []
    }
  }

  const getStoreProductList = useCallback(
    async (availableProductList?: any[]) => {
      if (!storeId) {
        return
      }
      setLoading(true)
      try {
        const productListResponse =
          await monetisationPujaApi.storesApi.getProductList(
            storeId,
            countryState?.countryCode,
            searchParams.get('sort_by') as ("price" | "position" | "created_at"),
            undefined,
            undefined,
            experiment,
            experiment !== '' ? variant : ""
          )

        const campaignList: any = await getCampaignList()
        const storeList: any = await getStoreList()
        const parsedProductData = parseStoreProductList(
          productListResponse?.data?.data, campaignList?.data?.data?.campaigns,
          storeList,
          languageState
        )
        setCampaignList(campaignList?.data?.data?.campaigns)

        removeAddeditemFromProductList(
          availableProductList || productList,
          parsedProductData,
        )
        setAddedList({ added: parsedProductData })
        createInventoryForm?.resetFields()
        createInventoryForm.setFieldsValue({ added: parsedProductData })
        setLoading(false)
      } catch (error) {

        console.log(error)
        message.error('Error while fetching store products', 3)
      }
    },
    [
      isEditJourney,
      monetisationPujaApi.storesApi,
      createInventoryForm,
      searchParams,
      countryState?.countryCode,
      experiment,
      variant
    ],
  )

  const getProductList = useCallback(async () => {
    if (currentStep !== 1) {
      return
    }
    try {
      const productList = await monetisationPujaApi.productApi.getProductList({
        limit: 500,
        offset: 0,
      }
      )
      return productList
    } catch (error) {
      setErrorPage(true)
      message.error({
        content: 'Error while fetching product list',
        duration: 3,
      })
      console.log(error)
      return undefined
    }
  }, [currentStep, monetisationPujaApi.productApi])

  useEffect(() => {
    if (currentStep !== 1) {
      return
    }
    if (experiment && !variant) {
      return
    }
    searchParams.set("language_code", languageState?.monetisationLanguage)
    searchParams.set("country_code", countryState?.countryCode)
    setSearchParams(searchParams)
    const productListResponse = getProductList()
    productListResponse
      ?.then((productList) => {
        if (!productList || !productList.data) {
          return
        }

        let filteredData = productList.data?.data;

        if (searchParams?.get?.('service_type') === 'pooja') {
          filteredData = filteredData?.filter((item: any) => item?.item_type !== "sankalp");
        } else {
          filteredData = filteredData?.filter((item: any) => item?.item_type !== "pooja");
        }

        setProductList(filteredData || []);
        if (currentStep === 1) {
          getStoreProductList(filteredData);
        }
      })
      .catch((error) => {
        message.error("Error while parsing product list");
        console.error("Error fetching product list:", error);
      });

  }, [storeId, currentStep, languageState.monetisationLanguage, getProductList])

  useEffect(() => {
    if (searchParams.get('service_type') === 'pooja') {
      setFilters({ item_type: searchParams.get('service_type') || '' })
    }
    createInventoryForm.setFieldsValue({ service_type: searchParams.get('service_type') })
  }, [searchParams])

  const removeAddeditemFromProductList = (
    productList: any[],
    parsedProductData: any[],
  ) => {
    if (!productList.length || !parsedProductData.length) {
      return
    }

    const newProductList = productList.filter(item => {
      return !parsedProductData.find(addedItem => {
        return addedItem.master_product_id === item.id
      })
    })

    setProductList(newProductList)
  }

  const addItem = async (item: Product) => {
    let addNewItem: any = {}
    addNewItem = formatInventoryForEditAdd(item, countryState?.countryCode, languageState?.monetisationLanguage)
    if (filters?.item_type === "pooja") {
      addNewItem['member_size'] = 1;
    }
    try {
      const store_id = searchParams.get('store_id') || ''
      await monetisationPujaApi.storesApi.createInventoryItem(store_id, addNewItem, experiment, variant)
      setTimeout(() => {
        window.location.reload()
      }, 100)
      const restItems = productList.filter(
        (templeItem: Product) => templeItem.id !== item.id,
      )
      setProductList([...restItems])
      getStoreProductList()
    } catch (error) {
      console.log(error)
    }
  }

  const showItemForm = () => {
    setShowItemCreation(true)
  }

  const updateInventoryItemApi = async () => {
    try {
      await monetisationPujaApi.storesApi.updateInventoryItem(
        nameListId,
        inventoryData,
        experiment,
        variant
      )
      closeItemCreationForm()
      setResetTrigger(true);
      setTimeout(() => {
        getStoreProductList()
      }, 500)
      message.success({ content: 'Item Updated Successfully', duration: 2 })
    } catch (error) {
      console.log('error occured while updating inventory item', error)
      message.error({
        content: 'Error occured while updating inventory item',
        duration: 2,
      })
    }
  }

  const handleOk = () => {
    setVisible(false)
    updateInventoryItemApi()
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const updateRecord = async (nameList: any) => {
    const formData = createInventoryForm.getFieldValue(nameList)

    if (filters?.item_type !== "pooja" &&
      (!formData?.intro_video_url?.length ||
        !formData?.outro_video_url?.length) &&
      formData?.active
    ) {
      message.error(
        'Please upload intro and outro video before enabling the inventory.',
      )
      return
    }
    const parsedData = formatInventoryForEdit(
      nameList,
      languageState.monetisationLanguage,
      countryState.countryCode,
      campaignList,
      searchParams?.get('service_type') as string,
      searchParams?.get('store_type') as string,
    )

    setNameListId(nameList.id)
    setInventoryData(parsedData)
    setVisible(true)
  }
  const redirectToItemCreation = () => {
    window.location.href = '/monetisation/puja/items?isCreateFlow=true'
  }

  const triggerEdit = (record: any) => {
    if ((record?.item_type === 'pooja') && record?.additional_info?.error) {
      message.warning('Error in Additional Info HTML Snippet', 2)
    }
    setEditInventory(record)
    setIsEditJourneyForm(true)
    setShowInventoryCreation(true)
  }

  const closeItemCreationForm = () => {
    setShowInventoryCreation(false)
    isEditJourneyForm && setIsEditJourneyForm(false)
    setEditInventory(undefined)
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPaginationParams({
      ...pagination,
    })
  }
  return (
    <>
      {errorPage ?
        <div style={{ height: '100vh' }}>
          <Error text='Error while fetching Inventories!' />
        </div> :
        <InventoryList
          AddedItems={addedList}
          triggerEdit={triggerEdit}
          productList={productList}
          added={addItem}
          createItem={showItemForm}
          currentStep={currentStep}
          previousHandler={previousHandler}
          nextHandler={() => nextHandler()}
          paginationParams={paginationParams}
          handleTableChange={handleTableChange}
          loading={loading}
          sortBy={searchParams.get('sort_by') as ("price" | "position" | "created_at")}
          updateSortBy={updateSortBy}
          getStoreProductList={() => getStoreProductList()}
          resetTrigger={resetTrigger} // Pass resetTrigger to child
          setResetTrigger={setResetTrigger}
        />
      }
      <InventoryForm
        showItemCreation={showInventoryCreation}
        closeItemCreation={closeItemCreationForm}
        form={createInventoryForm}
        initialValues={editInventory}
        updateRecord={updateRecord}
        cdnUrl={YODA_CDN_URL}
        preSignUrl={PUJA_PRESIGN_URL}
        service_type={searchParams.get('service_type') || ""}
        addedItems={addedList}
      />

      <Modal
        title='Basic Modal'
        visible={showItemCreation}
        onOk={redirectToItemCreation}
        onCancel={() => setShowItemCreation(false)}
      >
        <p>Are you sure you want to switch to item creation flow ?</p>
      </Modal>

      {inventoryData &&
        <ConfirmationPopup
          storeData={inventoryData}
          visible={visible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={'Inventory'}
        />
      }
    </>
  )
}

export default AssigningOfferStep
